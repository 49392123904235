import ApiService from '../../global/service/api';

export class SideBarService extends ApiService {
  getDatabases = async () => this.get({ url: 'databases/' });

  getBanks = async (db) => this.get({ url: 'banks/', params: { db } });

  getSelectData = async (db) => this.get({ url: 'data/', params: { db } });

  getCharts = async (data, db) => this.post({ url: 'charts/', body: data, params: { db } });

  getOperations = async (db) => this.get({ url: 'operations/', params: { db } });

  getColumns = async () => this.get({ url: `rowsColumnsByTable/1/` });

  getSelectedDataTemplate = async () => this.get({ url: 'selected_data_template/' });

  getPrivateBanksExportData = async (data) =>
    this.post({ url: 'export_private_banks/', body: data, responseType: 'blob' });

  uploadSurveyData = async (data) =>
    this.post({
      url: 'upload_bank_survey_data/',
      body: data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

  saveTemplate = async (data, db) =>
    this.post({ url: 'selected_data_template/', body: data, params: { db } });

  getTemplateById = async (id) => this.get({ url: `selected_data_template/${id}/` });
}

export default new SideBarService();

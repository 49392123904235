import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Table } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import * as actions from './actions';
import { selectors } from './reducer';
import * as Styled from './UsersPage.styles';
import Loader from '../../components/Loader';
import USERS_PAGE from './constants';
import { exportToExcel } from '../../global/helpers';

const { confirm } = Modal;

const UsersPage = () => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(selectors.usersPage);

  useEffect(() => {
    dispatch(actions.getUsers.request());
  }, [dispatch]);

  const handleDeleteUser = (user) => {
    confirm({
      title: USERS_PAGE.deleteModal.title,
      icon: <ExclamationCircleOutlined />,
      content: `User with id ${user.user_id} will be deleted`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        dispatch(actions.deleteUserById.request(user.id));
      },
      onCancel: () => {},
    });
  };

  const columns = [...USERS_PAGE.table.columns];
  columns[1].render = (text, record) => (
    <Styled.TableNameWrapper>
      <Link to={`/users/edit/${record.id}`}>{record.name}</Link>
    </Styled.TableNameWrapper>
  );
  columns[5].render = (text, record) => (
    <Styled.TableActionsWrapper>
      <DeleteOutlined onClick={() => handleDeleteUser(record)} />
    </Styled.TableActionsWrapper>
  );

  return (
    <Styled.UsersPageWrapper>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Styled.UsersPageHeader>
            <div className="page__title">{USERS_PAGE.title}</div>
            <div className="btns_container">
              <Link to="/users/add">
                <Button type="primary">{USERS_PAGE.buttons.newUser}</Button>
              </Link>
              <Button onClick={() => exportToExcel(data, 'Users')} type="primary">
                Download Excel
              </Button>
            </div>
          </Styled.UsersPageHeader>
          <Styled.TableWrapper>
            <Table columns={columns} dataSource={data} bordered />
          </Styled.TableWrapper>
        </>
      )}
    </Styled.UsersPageWrapper>
  );
};

export default UsersPage;

/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SaveOutlined, PrinterOutlined, AppstoreOutlined } from '@ant-design/icons';
import { Line, Bar, Pie } from 'react-chartjs-2';
import * as Styled from '../Charts.styles';
import CHART_SETTINGS from '../constants';
import {
  SIDE_BAR,
  PERCENT_OF_TOTAL_CREDIT_GRANTED,
  BAR_CHART_ENABLED,
} from '../../SideBar/constants';
import { selectors } from '../../SideBar/reducer';
import PrintDialog from '../../PrintDialog';
import SeriesSelectedTable from '../../SeriesSelectedTable';
import { openPrintDialog } from '../../SideBar/actions';
import { getSelectedPairs, getValuesFromKeys } from '../../../global/helpers';

const BigChart = ({ match, history }) => {
  const chart = match.params.id;
  const { number } = match.params;

  const dispatch = useDispatch();

  const charts = useSelector(selectors.charts);
  const targetKeys = useSelector(selectors.internalKeys);
  const tables = useSelector(selectors.dataForSelect);
  const columns = useSelector(selectors.columns);
  const treeData = useSelector(selectors.internalTreeData);

  const getTableData = () => {
    if (tables && columns) {
      const tableRows = Array.isArray(tables.data)
        ? tables.data.reduce(
            (accum, table) => [...accum, ...(table.data?.rows || table.rows || [])],
            [],
          )
        : Object.values(tables.data).reduce(
            (accum, table) => [...accum, ...(table.data?.rows || table.rows || [])],
            [],
          );

      const rows = tableRows.reduce(
        (accum, row) => ({
          ...accum,
          [row.rownumber]: `${row?.rownumber} - ${row?.itemdescription}`,
        }),
        {},
      );
      const values = getValuesFromKeys(targetKeys);
      const selectedPairs = getSelectedPairs(treeData, values, columns);
      const tableData = [];
      Object.entries(selectedPairs).forEach((pair) => {
        const pairTableAndRow = pair[0].split('-');
        tableData.push({
          column: pair[1].join(', '),
          row: rows[pairTableAndRow[1]],
          table: `${pairTableAndRow[0]}: ${tables[pairTableAndRow[0] - 1]?.itemdescription}`,
        });
      });
      return tableData;
    }
    return null;
  };

  const title = SIDE_BAR.points[chart];

  const chartRef = useRef();

  const mapChartsData = () => {
    if (charts) {
      const { colors } = CHART_SETTINGS;
      const subject = Object.entries(charts).filter((item) => item[0] === chart)[0];
      if (subject) {
        const value = subject[1];
        if (tables.type === 'private_banks') {
          const data = {
            labels: value.map((item) => item.label),
            datasets: value.map((item, id) => ({
              data: [item.data],
              backgroundColor: colors[id],
              borderColor: colors[id],
              label: item.label,
            })),
          };
          return data;
        }
        if (chart === 'MARKET_TOTAL_BAR_CHART') {
          const datasets = value.map((group, barNumber) => ({
            labels: group.map((v, id) => {
              const label = v.label.split(',');
              return label[3] ?? id;
            }),
            datasets: group.map((graph, id) => ({
              label: graph.label,
              data: graph.data,
              backgroundColor: colors[id],
              borderColor: colors[id],
              borderWidth: 1,
            })),
            download: value[barNumber]?.xlsx,
          }));
          return datasets[number]; // Returning the specific dataset based on `number`
        }
        if (chart === PERCENT_OF_TOTAL_CREDIT_GRANTED) {
          const datasets = value.map((group) => ({
            labels: group.map((item) => item.label),
            datasets: [
              {
                data: group.flatMap((item) => item.data),
                backgroundColor: group.map((_, id) => colors[id]),
                borderColor: group.map((_, id) => colors[id]),
                borderWidth: 1,
                fill: false,
              },
            ],
          }));
          return datasets.map((chartData, index) => ({
            ...chartData,
            download: value[index]?.xlsx,
          }))[number];
        }
        const datasets =
          chart === 'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS' ||
          chart === 'PERCENT_OF_ASSETS_REF_TABLE'
            ? value
                .filter((item) => !item.xlsx)
                .sort((item) => (item.type === 'line' ? -1 : 1))
                .map((item, index) => ({
                  label: item.label,
                  data: item.data,
                  fill: false,
                  backgroundColor: colors[index],
                  borderColor: colors[index],
                  type: item.type,
                  yAxisID: item.type === 'line' ? 'y1' : 'y',
                }))
            : value
                .filter((item) => !item.xlsx)
                .map((item, index) => ({
                  label: item.label,
                  data: item.data,
                  fill: false,
                  backgroundColor: colors[index],
                  borderColor: colors[index],
                  type: item.type,
                }));

        const data = {
          labels: Object.entries(charts)[0][1].map(
            (label) => `${label.slice(5, 7)}/${label.slice(2, 4)}`,
          ),
          datasets,
          download: value[value.length - 1]?.xlsx,
        };
        return data;
      }
      history.push('/');
      return null;
    }
    return null;
  };

  const value = charts
    ? Object.entries(charts)
        ?.filter((item) => item[0] === chart)
        ?.at(0)?.[1] || null
    : null;
  const scales =
    chart === 'MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS' ||
    chart === 'PERCENT_OF_ASSETS_REF_TABLE'
      ? {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            ticks: {
              callback: (tickValue) => `${tickValue}%`,
            },
          },
        }
      : null;
  const getPrintContent = () => (
    <Styled.ChartPrintContentWrapper ref={chartRef}>
      <Styled.ChartHeader>
        <Styled.ChartTitle>{title}</Styled.ChartTitle>
      </Styled.ChartHeader>
      {chart === PERCENT_OF_TOTAL_CREDIT_GRANTED ? (
        <Pie data={mapChartsData()} options={{ ...CHART_SETTINGS.options }} />
      ) : BAR_CHART_ENABLED.includes(chart) ? (
        <Bar data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
      ) : (
        <Line data={mapChartsData()} options={{ ...CHART_SETTINGS.options }} />
      )}
    </Styled.ChartPrintContentWrapper>
  );

  return (
    <>
      {charts && targetKeys && <SeriesSelectedTable data={getTableData()} />}
      {charts && (
        <Styled.ChartsBlockWrapperBig>
          <Styled.ChartContentWrapper ref={chartRef}>
            <Styled.ChartHeader>
              <Styled.ChartTitle>{title}</Styled.ChartTitle>
              <div className="controls__wrapper">
                {value && value.length > 0 && (
                  <a href={value[value.length - 1]?.xlsx}>
                    <Styled.ChartControl>
                      <SaveOutlined />
                    </Styled.ChartControl>
                  </a>
                )}
                <Styled.ChartControl onClick={() => dispatch(openPrintDialog(true))}>
                  <PrinterOutlined />
                </Styled.ChartControl>
              </div>
            </Styled.ChartHeader>
            {chart === PERCENT_OF_TOTAL_CREDIT_GRANTED ? (
              <Pie data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
            ) : BAR_CHART_ENABLED.includes(chart) ? (
              <Bar data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
            ) : (
              <Line data={mapChartsData()} options={{ ...CHART_SETTINGS.options, scales }} />
            )}
          </Styled.ChartContentWrapper>
        </Styled.ChartsBlockWrapperBig>
      )}
      {charts && (
        <Styled.GraphsWrapper>
          <Styled.AllGraphsControl onClick={() => history.push('/')}>
            <AppstoreOutlined />
            All Graphs
          </Styled.AllGraphsControl>
        </Styled.GraphsWrapper>
      )}
      {charts && <PrintDialog componentToPrint={getPrintContent} />}
    </>
  );
};

BigChart.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      number: PropTypes.number,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default BigChart;

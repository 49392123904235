/* eslint-disable no-debugger, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { asyncReducerCreator } from '../../global/redux';
import * as actions from './actions';

const initialState = {
  loading: false,
  databases: [],
  banks: [],
  columns: [],
  data: [],
  internalData: [],
  internalTreeData: null,
  internalKeys: [],
  selectedBanks: [],
  operations: [],
  selectedDatabases: [],
  selectedDatapoints: null,
  triggers: [],
  templates: [],
  selectedTemplate: null,
  charts: null,
  chartsLoading: false,
  inflationByYears: false,
  inflationChecked: false,
  date: null,
  keys: [],
  operationsMenuOpen: false,
  uploadFileOpen: false,
  printDialogOpen: false,
  datapointsOpen: false,
  treeData: null,
  error: false,
};

const reducer = createReducer(initialState, {
  ...asyncReducerCreator(
    actions.getDatabase,
    null,
    (state) => {
      state.loading = true;
      state.databases = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.databases = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getBanks,
    null,
    (state) => {
      state.loading = true;
      state.banks = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.banks = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getTables,
    null,
    (state) => {
      state.loading = true;
      state.tables = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.tables = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getTemplate,
    null,
    (state) => {
      state.loading = true;
      state.templates = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.templates = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getSelectedTemplate,
    null,
    (state) => {
      state.loading = true;
      state.selectedTemplate = null;
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.selectedTemplate = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getData,
    null,
    (state) => {
      state.loading = true;
      state.data = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  ),
  ...asyncReducerCreator(
    actions.getOperations,
    null,
    (state) => {
      state.loading = true;
      state.operations = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.operations = action.payload;
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  ),
  ...asyncReducerCreator(
    actions.getColumns,
    null,
    (state) => {
      state.loading = true;
      state.columns = [];
      state.error = false;
    },
    (state, action) => {
      state.loading = false;
      state.columns = action.payload;
      state.error = false;
    },
  ),
  ...asyncReducerCreator(
    actions.getCharts,
    null,
    (state) => {
      state.chartsLoading = true;
      state.charts = null;
      state.error = false;
      state.generationStatus = true;
    },
    (state, action) => {
      state.chartsLoading = false;
      state.charts = action.payload;
      state.error = false;
    },
    (state, action) => {
      state.chartsLoading = false;
      state.error = action.payload;
    },
  ),
  [actions.addTemplate]: (state, action) => {
    state.templates = [...state.templates, action.payload];
  },
  [actions.openOperationsMenu]: (state, action) => {
    state.operationsMenuOpen = action.payload;
  },
  [actions.openPrintDialog]: (state, action) => {
    state.printDialogOpen = action.payload;
  },
  [actions.openDatapoints]: (state, action) => {
    state.datapointsOpen = action.payload;
  },
  [actions.openUploadFilesModal]: (state, action) => {
    state.uploadFileOpen = action.payload;
  },
  [actions.setTreeData]: (state, action) => {
    state.treeData = action.payload;
  },
  [actions.setInternalTreeData]: (state, action) => {
    state.internalTreeData = action.payload;
  },
  [actions.setInternalTargetKeys]: (state, action) => {
    state.internalKeys = action.payload;
  },
  [actions.setSelectedBanks]: (state, action) => {
    state.selectedBanks = [...state.selectedBanks, action.payload];
  },
  [actions.setBanks]: (state, action) => {
    state.selectedBanks = action.payload;
  },
  [actions.updateInflationByYear]: (state, action) => {
    state.inflationByYears = action.payload;
  },
  [actions.updateInflationChecked]: (state, action) => {
    state.inflationChecked = action.payload;
  },
  [actions.unsetSelectedBanks]: (state, action) => {
    state.selectedBanks = state.selectedBanks.filter((bank) => bank !== action.payload);
  },
  [actions.resetSelectedBanks]: (state) => {
    state.selectedBanks = [];
  },
  [actions.resetOperations]: (state) => {
    state.operations = [];
  },
  [actions.updateKeys]: (state, action) => {
    state.keys = action.payload;
  },
  [actions.updateDataBase]: (state, action) => {
    state.selectedDatabases = action.payload;
  },
  [actions.updateInternalData]: (state, action) => {
    state.internalData = action.payload;
  },
  [actions.unsetDataBase]: (state, action) => {
    state.selectedDatabases = state.selectedDatabases.filter(
      (database) => database.key !== action.payload,
    );
  },
  [actions.updatePeriod]: (state, action) => {
    state.date = action.payload;
  },
  [actions.updateDataPoints]: (state, action) => {
    state.triggers = [...state.triggers, action.payload];
    state.selectedDatapoints = { ...state.selectedDatapoints, [action.payload]: true };
  },
  [actions.setDataPoints]: (state, action) => {
    const formatSelectedDatapoints = action.payload.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
    state.triggers = action.payload;
    state.selectedDatapoints = formatSelectedDatapoints;
  },
  [actions.unsetDataPoints]: (state, action) => {
    state.triggers = state.triggers.filter((triger) => triger !== action.payload);
    state.selectedDatapoints = { ...state.selectedDatapoints, [action.payload]: false };
  },
  [actions.resetDataPoints]: (state) => {
    state.triggers = [];
    state.selectedDatapoints = {};
  },
  [actions.clearSelectedData]: (state) => {
    state.loading = false;
    state.selectedBanks = [];
    state.selectedDatabases = [];
    state.keys = [];
    state.selectedDatapoints = null;
    state.triggers = [];
    state.date = null;
    state.charts = null;
  },
});

const databases = (state) => state.sideBarReducer.databases;
const banks = (state) => state.sideBarReducer.banks;
const rows = (state) => state.sideBarReducer.rows;
const columns = (state) => state.sideBarReducer.columns;
const operationsMenuOpen = (state) => state.sideBarReducer.operationsMenuOpen;
const printDialogOpen = (state) => state.sideBarReducer.printDialogOpen;
const datapointsOpen = (state) => state.sideBarReducer.datapointsOpen;
const treeData = (state) => state.sideBarReducer.treeData;
const selectedBanks = (state) => state.sideBarReducer.selectedBanks;
const selectedDatabases = (state) => state.sideBarReducer.selectedDatabases;
const targetKeys = (state) => state.sideBarReducer.keys;
const period = (state) => state.sideBarReducer.date;
const selectedDatapoints = (state) => state.sideBarReducer.selectedDatapoints;
const triggers = (state) => state.sideBarReducer.triggers;
const charts = (state) => state.sideBarReducer.charts;
const loading = (state) => state.sideBarReducer.chartsLoading;
const operations = (state) => state.sideBarReducer.operations;
const dataForSelect = (state) => state.sideBarReducer.data;
const internalData = (state) => state.sideBarReducer.internalData;
const internalTreeData = (state) => state.sideBarReducer.internalTreeData;
const internalKeys = (state) => state.sideBarReducer.internalKeys;
const templates = (state) => state.sideBarReducer.templates;
const selectedTemplate = (state) => state.sideBarReducer.selectedTemplate;
const inflationByYearsSelected = (state) => state.sideBarReducer.inflationByYears;
const inflationChecked = (state) => state.sideBarReducer.inflationChecked;
const uploadFileOpen = (state) => state.sideBarReducer.uploadFileOpen;

export const selectors = {
  databases,
  banks,
  operationsMenuOpen,
  printDialogOpen,
  datapointsOpen,
  dataForSelect,
  operations,
  internalData,
  treeData,
  rows,
  columns,
  selectedBanks,
  selectedDatabases,
  targetKeys,
  period,
  selectedDatapoints,
  triggers,
  charts,
  loading,
  internalTreeData,
  internalKeys,
  templates,
  selectedTemplate,
  inflationByYearsSelected,
  inflationChecked,
  uploadFileOpen,
};

export default reducer;

import styled from 'styled-components';

export const UsersPageWrapper = styled('div')`
  width: 100%;
  padding: 15px 10%;
`;

export const UsersPageHeader = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  .page__title {
    font-size: 32px;
    line-height: 39px;
    color: #5a5a5a;
    font-weight: 700;
  }

  .btns_container {
    display: flex;
    gap: 10px;
  }

  button {
    width: 190px;
  }
`;

export const TableWrapper = styled('div')`
  width: 100%;
`;

export const TableNameWrapper = styled('div')`
  width: 100%;

  a {
    font-size: 14px;
    line-height: 13px;
    color: #3554c5;
    font-weight: 600;
    text-decoration: underline;
  }
`;

export const TableActionsWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
  }
`;

export default UsersPageWrapper;

import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import moment from 'moment';
import * as Styled from './SideBar.styles';
import * as actions from './actions';
import SIDE_BAR, {
  DATABASES_AVAILABLE_DATES_FROM,
  PICKER_TYPE,
  CONSUMER_CREDIT_ID,
  DATABASES_SELECTED_AS_ONE,
  BANKS_SELECT_DISABLED,
  TIME_FORMATS,
} from './constants';

import { selectors } from './reducer';
import { selectors as userSelectors } from '../../containers/LoginPage/reducer';
import OperationsWindow from '../OperationsWindow';
import { getFormatedData, getValuesFromKeys, getDbKeys } from '../../global/helpers';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

const DataSelect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const now = new Date();

  const operationsMenuOpen = useSelector(selectors.operationsMenuOpen);
  const accessToken = !!useSelector(userSelectors.accessToken);
  const refreshToken = useSelector(userSelectors.refreshToken);

  const addBank = (option) => dispatch(actions.setSelectedBanks(option));
  const removeBank = (option) => dispatch(actions.unsetSelectedBanks(option));
  const selectColumns = (keys) => dispatch(actions.updateKeys(keys));
  const setDatabase = (db) => dispatch(actions.updateDataBase(db));
  const getDataField = (db) => dispatch(actions.getData.request(db));
  const getOperations = (db) => dispatch(actions.getOperations.request(db));
  const updateInflationByYear = (option) => dispatch(actions.updateInflationByYear(option));
  const updateInflationChecked = (option) => dispatch(actions.updateInflationChecked(option));
  const getBanks = (db) => dispatch(actions.getBanks.request(db));
  const setPeriod = (period) => dispatch(actions.updatePeriod(period));
  const clearData = () => {
    dispatch(actions.clearSelectedData());
    history.push('/');
  };
  const unsetDatapoints = (key) => {
    dispatch(actions.unsetDataPoints(key));
    dispatch(actions.openDatapoints(false));
  };
  const setDatapoints = (key) => {
    dispatch(actions.updateDataPoints(key));
    dispatch(actions.openDatapoints(false));
  };

  useEffect(() => {
    if (accessToken || refreshToken) {
      dispatch(actions.getDatabase.request());
      getBanks();
      dispatch(actions.getColumns.request());
      dispatch(actions.getTemplate.request());
    }
  }, [dispatch, accessToken, refreshToken]);

  const { Option } = Select;
  const banks = useSelector(selectors.banks);
  const databases = useSelector(selectors.databases);
  const selectedBanks = useSelector(selectors.selectedBanks);
  const selectedDatabases = useSelector(selectors.selectedDatabases);
  const selectedDatapoints = useSelector(selectors.selectedDatapoints);
  const templates = useSelector(selectors.templates);

  const inflationChecked = useSelector(selectors.inflationChecked);
  const inflationCheckedByYear = useSelector(selectors.inflationByYearsSelected);
  const selectedTemplates = useSelector(selectors.selectedTemplate);
  const triggers = useSelector(selectors.triggers);
  const targetKeys = useSelector(selectors.targetKeys);
  const period = useSelector(selectors.period);
  const operations = useSelector(selectors.operations);
  const dataForSelect = useSelector(selectors.dataForSelect);
  const datapointsOpen = useSelector(selectors.datapointsOpen);
  const bankSelectDisabled = selectedDatabases.some((db) => BANKS_SELECT_DISABLED?.includes(db));
  // eslint-disable-next-line no-unused-vars
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const isMarkedTotalSelected =
    selectedDatapoints &&
    !selectedDatabases.includes(CONSUMER_CREDIT_ID) &&
    'TARGET_TABLE' in selectedDatapoints &&
    selectedDatapoints.TARGET_TABLE === true;

  const openDatapoints = () => {
    if (!datapointsOpen) {
      dispatch(actions.openDatapoints(true));
    }
  };
  const [isDate, setIsDate] = useState(false);
  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    if (databases?.length > 0) {
      const defaultDatabase = databases[0]?.bdid;
      setDatabase([defaultDatabase]);
      getDataField(defaultDatabase);
      getOperations(defaultDatabase);
    }
  }, [databases]);

  useEffect(() => {
    if (!period)
      dispatch(
        actions.updatePeriod([
          null,
          moment(`${now.getFullYear()}/${now.getMonth() + 1}`, 'YYYY/MM'),
        ]),
      );
    // eslint-disable-next-line
  }, [period, dispatch]);

  const treeData = useMemo(() => {
    switch (dataForSelect.type) {
      case 'tables':
        return [...(dataForSelect?.data || [])]
          .sort((a, b) => a.tablenumber - b.tablenumber)
          .map((table) => ({
            title: `Table ${table.tablenumber}: ${table.itemdescription}`,
            key: table.tablenumber,
            checkable: false,
            children: table.data.rows.map((item) => ({
              title: `Row ${item.rownumber}: ${item.itemdescription}`,
              key: `${item.rownumber}t${table.tablenumber}`,
              checkable: false,
              children: table.data.columns.map((col) => ({
                title: col.columndescription,
                key: `${col.columnnumber}-${item.rownumber}-${table.tablenumber}`,
              })),
            })),
          }));
      case 'rows':
        return [...(dataForSelect?.data || [])].map((table) => ({
          title: table.itemdescription,
          key: table.itemdescription,
          checkable: false,
          children: table.data.map((row) => ({
            title: row.itemdescription,
            key: `1-1-${row.rownumber}`,
          })),
        }));
      case 'private_banks':
        return Object.keys(dataForSelect.data).map((table) => ({
          title: table,
          key: table,
          checkable: false,
          children: Object.values(dataForSelect.data[table]).map((item) => ({
            title: item,
            key: `${table}:${item}`,
          })),
        }));
      default:
        return dataForSelect?.data
          ? Object.keys(dataForSelect.data).map((table) => ({
              title: table,
              key: table,
              checkable: false,
              children: Object.keys(dataForSelect.data[table]).map((item) => ({
                title: item,
                key: `${table}t${item}`,
                checkable: false,
                children: Object.keys(dataForSelect.data[table][item]).map((row) => ({
                  title: row,
                  key: `${table}-${item}-${row}`,
                  checkable: false,
                  children: Object.values(dataForSelect.data[table][item][row]).map((innerRow) => ({
                    key: `${table}-${item}-${row}-${innerRow}`,
                    title: innerRow,
                  })),
                })),
              })),
            }))
          : [];
    }
  }, [dataForSelect]);

  useEffect(() => {
    if (treeData || treeData?.length > 0) {
      dispatch(actions.setTreeData(treeData));
    }
  }, [dispatch, treeData]);

  const handleTemplateAndChartGeneration = (isSave = false) => {
    if (period && selectedBanks?.length > 0 && targetKeys?.length > 0) {
      const startDate = period[0];
      const endDate = period[1];
      if (!startDate || !endDate) {
        openNotification({
          type: NOTIFICATIONS_CONFIG.types.warning,
          message: `Please select period`,
        });
        return;
      }
      const selectedTables = [];
      const selectedRows = [];
      const values = getValuesFromKeys(targetKeys);
      const getTriggers = () => {
        if (selectedDatapoints) {
          return {
            ...SIDE_BAR.intialTrigger,
            ...selectedDatapoints,
            MARKET_TOTAL_WITH_CRISIS_INDEX: inflationChecked,
            MARKET_TOTAL_WITH_CRISIS_INDEX_BY_YEAR: inflationCheckedByYear,
          };
        }
        return {
          TARGET_TABLE: true,
          MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS: true,
          PERCENT_OF_ASSETS_REF_TABLE: true,
          MARKET_SHARE_REF: true,
        };
      };
      values?.forEach(({ table, row }) => {
        if (!selectedTables.includes(table)) {
          selectedTables.push(table);
        }
        if (!selectedRows.includes(row)) selectedRows.push(row);
      });
      const db = getDbKeys(selectedDatabases, databases);
      const data = {
        db,
        banks_id_list: selectedBanks
          ?.map((item) => banks?.find(({ name }) => name === item))
          ?.map(({ id }) => id)
          .flat(),
        period: [
          getFormatedData(startDate, TIME_FORMATS[selectedDatabases[0]] ?? 'YYYY-MM'),
          getFormatedData(endDate, TIME_FORMATS[selectedDatabases[0]] ?? 'YYYY-MM'),
        ],
        tables:
          dataForSelect.type === 'consumer_credit'
            ? targetKeys.map((item) => {
                const parts = item?.split(/-(?=[A-Z])/);
                const [sheetNumber, blockType, totalSumValue] = parts
                  .slice(0, 3)
                  .map((part) => part.trim());
                let agreement = '';
                let totalSum = totalSumValue;
                if (totalSumValue.includes('-')) {
                  const splittedValues = totalSumValue.split('-');
                  const total = splittedValues[0];
                  const agreementNew = splittedValues.slice(1).join('-');
                  agreement = agreementNew;
                  totalSum = total;
                } else {
                  agreement = parts.slice(3).join('-');
                }
                return {
                  sheet_number: sheetNumber,
                  block_type: blockType || null,
                  total_sum_value: totalSum || totalSumValue || null,
                  agreement: agreement || null,
                };
              })
            : selectedTables.map((id) => {
                switch (dataForSelect.type) {
                  case 'rows':
                    return {
                      table_id: '1',
                      rows: selectedRows.map(() => ({
                        id: String(id),
                        columns: ['1'],
                      })),
                    };
                  case 'private_banks':
                    // eslint-disable-next-line no-case-declarations
                    const getKeys = (targetKeys || []).reduce((acc, item) => {
                      if (typeof item === 'string' && item.includes(':')) {
                        const [key, value] = item.split(':');
                        // eslint-disable-next-line no-prototype-builtins
                        if (acc?.hasOwnProperty(key)) {
                          acc[key.toLowerCase()].push(value);
                        } else {
                          acc[key.toLowerCase()] = [value];
                        }
                      }
                      return acc;
                    }, {});
                    return getKeys;
                  default:
                    return {
                      table_id: id,
                      rows: selectedRows.map((rowId) => {
                        const filteredValues = values?.filter(
                          ({ row, table }) => table === id && rowId === row,
                        );
                        return {
                          id: rowId,
                          columns: filteredValues ? filteredValues.map(({ column }) => column) : [],
                        };
                      }),
                    };
                }
              }),
        trigger: getTriggers(),
      };
      if (isSave) {
        const requierdFields = [
          { key: 'date', value: period },
          { key: 'banks', value: selectedBanks },
          { key: 'tables', value: targetKeys },
          { key: 'template name', value: templateName },
          { key: 'operations', value: Object.values(selectedDatapoints ?? {}) },
        ];
        const postData = {
          name: templateName,
          extra_data: data,
        };
        if (
          targetKeys.length &&
          period.length &&
          selectedBanks.length &&
          templateName &&
          Object.values(selectedDatapoints).includes(true)
        ) {
          dispatch(actions.saveTemplate.request(postData));
        } else {
          requierdFields.forEach(({ key, value }) => {
            if (key === 'operations' && !Object.values(selectedDatapoints ?? {}).includes(true)) {
              openNotification({
                type: NOTIFICATIONS_CONFIG.types.warning,
                message: `Please select some operations`,
              });
              return;
            }
            if (!value || value.length === 0) {
              openNotification({
                type: NOTIFICATIONS_CONFIG.types.warning,
                message: `Please select some ${key}`,
              });
            }
          });
        }
      } else {
        dispatch(actions.getCharts.request(data));
        dispatch(actions.updateInternalData(dataForSelect));
        dispatch(actions.setInternalTreeData(treeData));
        dispatch(actions.setInternalTargetKeys(targetKeys));
      }
    } else {
      const requierdFields = [
        { key: 'date', value: period },
        { key: 'banks', value: selectedBanks },
        { key: 'tables', value: targetKeys },
      ];
      requierdFields.forEach(({ key, value }) => {
        if (!value || value.length === 0) {
          openNotification({
            type: NOTIFICATIONS_CONFIG.types.warning,
            message: `Please select some ${key}`,
          });
        }
      });
    }
  };

  const clearDataOnDbSelect = () => {
    dispatch(actions.updateKeys([]));
    dispatch(actions.resetDataPoints());
    dispatch(actions.resetSelectedBanks());
    dispatch(actions.resetOperations());
  };

  const disabledDate = (current) =>
    // eslint-disable-next-line
    selectedDatabases.some((db) => DATABASES_AVAILABLE_DATES_FROM?.hasOwnProperty(db)) &&
    current &&
    current <
      moment(
        Math.min(
          ...selectedDatabases
            // eslint-disable-next-line
            .filter((db) => DATABASES_AVAILABLE_DATES_FROM?.hasOwnProperty(db))
            .map((db) => moment(DATABASES_AVAILABLE_DATES_FROM[db]).valueOf()),
        ),
      );

  const onDataBaseSelect = (db) => {
    const hasCommonDatabases = DATABASES_SELECTED_AS_ONE.some((database) =>
      selectedDatabases.includes(database),
    );
    if (DATABASES_SELECTED_AS_ONE.includes(db) || hasCommonDatabases) {
      setDatabase([db]);
      const keys = getDbKeys([db], databases);
      getDataField(keys);
      getBanks(keys);
      getOperations(keys);
    } else {
      setDatabase([...selectedDatabases, db]);
      const keys = getDbKeys([...selectedDatabases, db], databases);
      getDataField(keys);
      getBanks(keys);
      getOperations(keys);
    }
    clearDataOnDbSelect();
  };

  const onDataBaseDeselect = (db) => {
    const filteredDatabases = selectedDatabases.filter((key) => db !== key);
    const keys = getDbKeys(filteredDatabases, databases);
    if (filteredDatabases.length) {
      clearDataOnDbSelect();
      getBanks(keys);
      getOperations(keys);
      getDataField(keys);
    }
    setDatabase(filteredDatabases);
  };

  const handleInputChange = (e) => {
    setTemplateName(e.target.value);
  };

  const onSelectTemplate = (t) => {
    const template = templates.find((temp) => temp.name === t);
    setTemplateName(template.name);
    dispatch(actions.getSelectedTemplate.request({ id: template.id, db: template.db }));
  };

  return (
    <Styled.SideBarWrapper
      onClick={() => {
        if (!isDate) dispatch(actions.openDatapoints(false));
      }}
    >
      <Styled.SideBarInputsWrapper>
        {templates?.length ? (
          <>
            <Styled.SideBarInputHeading>{SIDE_BAR.templatesHeadingText}</Styled.SideBarInputHeading>
            <Styled.SideBarSelect
              mode="single"
              maxTagCount="responsive"
              onSelect={onSelectTemplate}
              onDeselect={setSelectedTemplate}
              value={selectedTemplates?.name ?? ''}
            >
              {templates?.map((item, id) => (
                <Option key={`${item.name}-${id * 2}`} value={item?.name}>
                  {item.name}
                </Option>
              ))}
            </Styled.SideBarSelect>
          </>
        ) : null}
        <Styled.SideBarInputHeading>{SIDE_BAR.databaseHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onSelect={onDataBaseSelect}
          onDeselect={onDataBaseDeselect}
          value={selectedDatabases}
        >
          {databases?.map((item) => (
            <Option key={item.bdid} value={item.bdid}>
              {item.bdid}
            </Option>
          ))}
        </Styled.SideBarSelect>
        {bankSelectDisabled ? null : (
          <>
            <Styled.SideBarInputHeading>{SIDE_BAR.banksHeadingText}</Styled.SideBarInputHeading>
            <Styled.SideBarSelect
              mode="multiple"
              maxTagCount="responsive"
              onSelect={addBank}
              onDeselect={removeBank}
              value={selectedBanks}
            >
              {banks &&
                [...banks]
                  .sort((a, b) => {
                    if (SIDE_BAR.defaultBanks.includes(a.name.toLowerCase())) {
                      return 1;
                    }
                    if (SIDE_BAR.defaultBanks.includes(b.name.toLowerCase())) {
                      return -1;
                    }
                    if (a.name.toLowerCase() < b.name.toLowerCase()) {
                      return -1;
                    }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item) => (
                    <Option key={item.id} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
            </Styled.SideBarSelect>
          </>
        )}
        <Styled.SideBarInputHeading>{SIDE_BAR.periodHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarDate
          disabledDate={disabledDate}
          onChange={setPeriod}
          picker={PICKER_TYPE[selectedDatabases[0]] ?? 'date'}
          value={period}
          format="YYYY/MM"
        />
        <Styled.SideBarOperationsWrapper>
          <Styled.SideBarInputHeading>{SIDE_BAR.operationsHeadingText}</Styled.SideBarInputHeading>
          <Styled.SideBarOperationsInput
            onClick={() => {
              dispatch(actions.openOperationsMenu(true));
            }}
          />
        </Styled.SideBarOperationsWrapper>
        <Styled.SideBarInputHeading>{SIDE_BAR.datapointsHeadingText}</Styled.SideBarInputHeading>
        <Styled.SideBarSelect
          mode="multiple"
          maxTagCount="responsive"
          onSelect={setDatapoints}
          onDeselect={unsetDatapoints}
          value={triggers}
          open={datapointsOpen}
          onClick={openDatapoints}
          onMouseLeave={() => setIsDate(false)}
          onMouseEnter={() => setIsDate(true)}
        >
          {operations
            .filter((operation) => operation.key !== 'MARKET_TOTAL')
            .map((operation) => (
              <Option
                value={operation.key}
                key={operation.key}
                onMouseLeave={() => setIsDate(false)}
                onMouseEnter={() => setIsDate(true)}
              >
                {operation.title}
              </Option>
            ))}
        </Styled.SideBarSelect>
        <Styled.SideBarInputHeading>{SIDE_BAR.templateName}</Styled.SideBarInputHeading>
        <Styled.SideBarInput value={templateName} onChange={handleInputChange} />
        {isMarkedTotalSelected ? (
          <Styled.SwitchOuterContainer>
            <Styled.SwitchRow>
              <Styled.SwitchText>Enable inflation coefficient</Styled.SwitchText>
              <Styled.StyledSwitch checked={inflationChecked} onChange={updateInflationChecked} />
            </Styled.SwitchRow>
            <Styled.SwitchRow>
              <Styled.SwitchText>Enable inflation coefficient by years</Styled.SwitchText>
              <Styled.StyledSwitch
                checked={inflationCheckedByYear}
                onChange={updateInflationByYear}
              />
            </Styled.SwitchRow>
          </Styled.SwitchOuterContainer>
        ) : null}
      </Styled.SideBarInputsWrapper>
      <Styled.SideBarControlsWrapper>
        <Styled.ResetButton onClick={clearData}>
          <RedoOutlined />
          {SIDE_BAR.resetButtonText}
        </Styled.ResetButton>
        <Styled.ActionButton onClick={() => handleTemplateAndChartGeneration()}>
          {SIDE_BAR.generateButtonText}
        </Styled.ActionButton>
        <Styled.ActionButton onClick={() => handleTemplateAndChartGeneration(true)}>
          {SIDE_BAR.saveTemplate}
        </Styled.ActionButton>
      </Styled.SideBarControlsWrapper>
      <Styled.ShowSelectedDataWrapper>
        <Styled.ShowSelectedDataHeading>
          {SIDE_BAR.banksSelectedText}
        </Styled.ShowSelectedDataHeading>
        <Styled.ShowSelectedDataText>
          {selectedBanks.length > 0 &&
            selectedBanks
              ?.slice()
              ?.sort((a, b) => a - b)
              ?.map((bank) => <p key={bank}>{bank}</p>)}
        </Styled.ShowSelectedDataText>
        <Styled.ShowSelectedDataHeading>
          {SIDE_BAR.seriesSelectedText}
        </Styled.ShowSelectedDataHeading>
        <Styled.ShowSelectedDataTable
          dataSource={getValuesFromKeys(targetKeys)}
          columns={SIDE_BAR.tableColumns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </Styled.ShowSelectedDataWrapper>
      {operationsMenuOpen && (
        <Styled.OperationsMenu
          width="calc(100%-100px)"
          heigh="800px"
          footer={null}
          onCancel={() => dispatch(actions.openOperationsMenu(false))}
          visible
        >
          <OperationsWindow
            onChange={selectColumns}
            targetKeys={targetKeys}
            dataSource={treeData}
          />
          <Styled.OperationsControlsWrapper>
            <Styled.ResetTablesButton onClick={() => selectColumns([])}>
              <RedoOutlined />
              {SIDE_BAR.operationsRefreshButtonText}
            </Styled.ResetTablesButton>
            <Styled.AddTablesButton onClick={() => dispatch(actions.openOperationsMenu(false))}>
              {SIDE_BAR.operationsAddButtonText}
            </Styled.AddTablesButton>
          </Styled.OperationsControlsWrapper>
        </Styled.OperationsMenu>
      )}
    </Styled.SideBarWrapper>
  );
};

export default DataSelect;

import { createAction } from '@reduxjs/toolkit';
import { asyncActionCreator } from '../../global/redux';
import * as constants from './constants';

export const getBanks = asyncActionCreator(constants.GET_BANKS);
export const setBanks = createAction(constants.SET_BANKS);
export const getDatabase = asyncActionCreator(constants.GET_DATABASE);
export const getRowsColumnByTable = asyncActionCreator(constants.GET_ROWS_COLUMNS_BY_TABLES);
export const getColumns = asyncActionCreator(constants.GET_COLUMNS);
export const updateInflationByYear = createAction(constants.UPDATE_INFLATION_BY_YEAR);
export const updateInflationChecked = createAction(constants.UPDATE_INFLATION_CHECKED);
export const addTemplate = createAction(constants.ADD_TEMPLATE);
export const getTables = asyncActionCreator(constants.GET_TABLES);
export const getData = asyncActionCreator(constants.GET_DATA);
export const getExportPrivateBanks = asyncActionCreator(constants.GET_EXPORT_PRIVATE_BANKS);
export const getTemplate = asyncActionCreator(constants.GET_SELECTED_DATA_TEMPLATE);
export const getSelectedTemplate = asyncActionCreator(constants.GET_SELECTED_TEMPLATE);
export const saveTemplate = asyncActionCreator(constants.POST_TEMPLATE);
export const getInternalData = asyncActionCreator(constants.GET_INTERNAL_DATA);
export const getOperations = asyncActionCreator(constants.GET_OPERATIONS);
export const getCharts = asyncActionCreator(constants.GET_CHARTS);
export const setInternalTreeData = createAction(constants.SET_INTERNAL_TREE_DATA);
export const setInternalTargetKeys = createAction(constants.SET_INTERNAL_TARGET_KEYS);
export const openOperationsMenu = createAction(constants.OPEN_OPERATIONS_MENU);
export const openPrintDialog = createAction(constants.OPEN_PRINT_DIALOG);
export const openDatapoints = createAction(constants.OPEN_DATAPOINTS);
export const openUploadFilesModal = createAction(constants.OPEN_UPLOAD_FILE_MODAL);
export const uploadFile = asyncActionCreator(constants.UPLOAD_FILE);
export const setTreeData = createAction(constants.SET_TREE_DATA);
export const setSelectedBanks = createAction(constants.SET_SELECTED_BANKS);
export const unsetSelectedBanks = createAction(constants.UNSET_SELECTED_BANKS);
export const resetSelectedBanks = createAction(constants.RESET_SELECTED_BANKS);
export const resetOperations = createAction(constants.RESET_OPERATIONS);
export const updateDataBase = createAction(constants.UPDATE_DATABASE);
export const updateInternalData = createAction(constants.UPDATE_INTERNAL_DATA);
export const unsetDataBase = createAction(constants.UNSET_DATABASE);
export const updatePeriod = createAction(constants.UPDATE_PERIOD);
export const updateKeys = createAction(constants.UPDATE_KEYS);
export const updateDataPoints = createAction(constants.UPDATE_DATAPOINTS);
export const setDataPoints = createAction(constants.SET_DATAPOINTS);
export const resetDataPoints = createAction(constants.RESET_DATAPOINTS);
export const unsetDataPoints = createAction(constants.UNSET_DATAPOINTS);
export const clearSelectedData = createAction(constants.CLEAR_SELECTED_DATA);

export default getBanks;

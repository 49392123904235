import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Link, useHistory } from 'react-router-dom';
import * as Styled from './Header.styles';
import logo from '../../global/images/logo.png';
import HEADER from './constants';
import { logout as logoutAction } from '../../containers/LoginPage/actions';
import { clearSelectedData as clearData, openUploadFilesModal } from '../SideBar/actions';
import { selectors } from '../../containers/LoginPage/reducer';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userRole = useSelector(selectors.userRole);

  const controls = { ...HEADER.controls };
  const onLogout = () => {
    dispatch(clearData());
    dispatch(logoutAction());
  };

  // eslint-disable-next-line
  const isLogin = useSelector(selectors.accessToken) ? true : false;
  // eslint-disable-next-line
  const isRefresh = useSelector(selectors.refreshToken) ? true : false;

  const openUploadFileModal = () => {
    dispatch(openUploadFilesModal(true));
  };

  useEffect(() => {
    if (!isLogin || !isRefresh) {
      onLogout();
    }
  }, [isLogin, history]);

  return (
    <Styled.HeaderWrapper isLoginPage={!userRole} className="application__header">
      <Link to={userRole ? '/' : '/login'}>
        <Styled.Logo>
          <img src={logo} alt="Intellidex Logotype" />
        </Styled.Logo>
      </Link>
      {userRole && (
        <Styled.Info>
          <span className="text__bold">{HEADER.title}</span>
          <span className="text__info">
            {HEADER.email} <a href="mailto:support@intellidex.co.za">{HEADER.emailData}</a>
          </span>
          <span className="text__info">
            {HEADER.phone} <a href="tel:+27100720472">{HEADER.phoneData}</a>
          </span>
          <Styled.InfoControls>
            <button type="button" onClick={openUploadFileModal} className="link">
              Upload files
            </button>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.intellidex.co.za/bankanalyser-user-guide/"
            >
              <button type="button" className="link" key={controls.guide.id}>
                {controls.guide.name}
              </button>
            </a>
            {userRole === 1 && (
              <NavLink
                key={controls.user.id}
                to={controls.user.link}
                activeClassName="active"
                className="link"
                onClick={() => dispatch(clearData())}
              >
                {controls.user.name}
              </NavLink>
            )}
            <button key={controls.logout.id} type="button" className="link" onClick={onLogout}>
              {controls.logout.name}
            </button>
          </Styled.InfoControls>
        </Styled.Info>
      )}
    </Styled.HeaderWrapper>
  );
};

export default Header;

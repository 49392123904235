import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Styled from './DashboardPage.style';
import Charts from '../../components/Charts';
import { selectors } from '../../components/SideBar/reducer';
import * as actions from '../../components/SideBar/actions';
import SIDE_BAR, {
  BA200,
  CONSUMER_CREDIT_ID,
  PERCENT_OF_TOTAL_RISK,
  PERCENT_OF_TOTAL_GROSS_CREDIT,
  PERCENT_OF_TOTAL_CREDIT_GRANTED,
  MARKET_TOTAL_BAR_CHART,
  DEFAULT_PLUGINS,
  TIME_FORMATS,
} from '../../components/SideBar/constants';
import Loader from '../../components/Loader';
import PrintDialog from '../../components/PrintDialog';
import SeriesSelectedTable from '../../components/SeriesSelectedTable';
import CHART_SETTINGS from '../../components/Charts/constants';
import {
  getSelectedPairs,
  getValuesFromKeys,
  flatten,
  transformQuarterString,
  getFormatedData,
} from '../../global/helpers';

const DashboardPage = () => {
  const chartsRef = useRef();
  const dispatch = useDispatch();

  const inflationByYearsSelected = useSelector(selectors.inflationByYearsSelected);
  const chartsData = useSelector(selectors.charts);
  const loading = useSelector(selectors.loading);
  const datapoints = useSelector(selectors.selectedDatapoints);
  const targetKeys = useSelector(selectors.internalKeys);
  const tables = useSelector(selectors.internalData);
  const banks = useSelector(selectors.banks);
  const selectedBanks = useSelector(selectors.selectedBanks);
  const dataForSelect = useSelector(selectors.dataForSelect);
  const period = useSelector(selectors.period);
  const columns = useSelector(selectors.columns);
  const treeData = useSelector(selectors.internalTreeData);
  const selectedDatabases = useSelector(selectors.selectedDatabases);
  const isBA200Selected = selectedDatabases.includes(BA200);
  const isConsumerCreditSelected = selectedDatabases.includes(CONSUMER_CREDIT_ID);

  const getTableData = () => {
    if (tables && columns) {
      const tableRows = Array.isArray(tables.data)
        ? tables.data.reduce(
            (accum, table) => [...accum, ...(table.data?.rows || table.rows || [])],
            [],
          )
        : Object.values(tables.data).reduce(
            (accum, table) => [...accum, ...(table.data?.rows || table.rows || [])],
            [],
          );

      const rows = tableRows.reduce(
        (accum, row) => ({
          ...accum,
          [row.rownumber]: `${row?.rownumber} - ${row?.itemdescription}`,
        }),
        {},
      );

      const values = getValuesFromKeys(targetKeys);
      const selectedPairs = getSelectedPairs(treeData, values, columns);
      const tableData = [];
      Object.entries(selectedPairs).forEach((pair, index) => {
        const pairTableAndRow = pair[0].split('-');
        if (isBA200Selected) {
          tableData.push({
            column: values[index].table,
            row: 1,
            table: 1,
          });
        } else if (isConsumerCreditSelected) {
          tableData.push({
            column: values[index].infoRow,
            row: values[index].row,
            table: values[index].column,
          });
        } else {
          tableData.push({
            column: pair[1].join(', '),
            row: rows[pairTableAndRow[1]],
            table: `${pairTableAndRow[0]}: ${tables[pairTableAndRow[0] - 1]?.itemdescription}`,
          });
        }
      });
      return tableData;
    }
    return null;
  };

  const requestFile = () => {
    const startDate = period[0];
    const endDate = period[1];
    const selectedTables = [];
    const selectedRows = [];
    const values = getValuesFromKeys(targetKeys);

    values?.forEach(({ table, row }) => {
      if (!selectedTables.includes(table)) {
        selectedTables.push(table);
      }
      if (!selectedRows.includes(row)) selectedRows.push(row);
    });
    const data = {
      banks_ids: selectedBanks
        ?.map((item) => banks?.find(({ name }) => name === item))
        ?.map(({ id }) => id)
        .flat(),
      period: [
        getFormatedData(startDate, TIME_FORMATS[selectedDatabases[0]] ?? 'YYYY-MM'),
        getFormatedData(endDate, TIME_FORMATS[selectedDatabases[0]] ?? 'YYYY-MM'),
      ],
      tables: selectedTables.map(() => {
        switch (dataForSelect.type) {
          case 'private_banks':
            // eslint-disable-next-line no-case-declarations
            const getKeys = (targetKeys || []).reduce((acc, item) => {
              if (typeof item === 'string' && item.includes(':')) {
                const [key, value] = item.split(':');
                // eslint-disable-next-line no-prototype-builtins
                if (acc?.hasOwnProperty(key)) {
                  acc[key.toLowerCase()].push(value);
                } else {
                  acc[key.toLowerCase()] = [value];
                }
              }
              return acc;
            }, {});
            return getKeys;
          default:
            return {};
        }
      }),
    };
    dispatch(actions.getExportPrivateBanks.request(data));
  };

  const renderCharts = () => {
    if (!datapoints || Object.entries(datapoints)) {
      const { colors } = CHART_SETTINGS;
      const dataSource = flatten(treeData);
      const selectedDataLabels = dataSource.filter((item) => targetKeys.includes(item.key));
      const entries = Object.entries(chartsData);
      const charts = entries.map(([key, value]) => {
        if (key !== 'labels' && value.length !== 0) {
          const chartTitle = SIDE_BAR.points[key];
          if (dataForSelect.type === 'private_banks') {
            const data = {
              labels: chartsData.labels.map((label) => label),
              datasets: value.map((item, id) => ({
                data: [item.data],
                backgroundColor: colors[id],
                borderColor: colors[id],
                label: item.label,
              })),
            };
            return (
              <Charts
                title={chartTitle}
                data={data}
                key={key}
                getFile={requestFile}
                link={key}
                pieChart={false}
                scales={null}
                options={DEFAULT_PLUGINS}
              />
            );
          }
          if (key === MARKET_TOTAL_BAR_CHART) {
            return value.map((group, barNumber) => {
              const chartData = {
                labels: group.map((v, id) => {
                  const label = v.label.split(',');
                  return label[3] ?? id;
                }),
                datasets: group.map((chart, id) => ({
                  label: chart.label,
                  data: chart.data,
                  backgroundColor: colors[id],
                  borderColor: colors[id],
                  borderWidth: 1,
                })),
              };
              return (
                <Charts
                  title={chartTitle}
                  data={chartData}
                  key={key}
                  number={barNumber}
                  link={key}
                  pieChart={false}
                  scales={null}
                  options={DEFAULT_PLUGINS}
                />
              );
            });
          }
          if (key === PERCENT_OF_TOTAL_CREDIT_GRANTED) {
            return value.map((group, pieNumber) => {
              const chartData = {
                labels: group.map((item) => item.label),
                datasets: [
                  {
                    data: group.map((item) => item.data),
                    backgroundColor: group.map((_, id) => colors[id]),
                    borderColor: group.map((_, id) => colors[id]),
                    borderWidth: 1,
                    fill: false,
                  },
                ],
              };
              return (
                <Charts
                  title={chartTitle}
                  data={chartData}
                  key={key}
                  number={pieNumber}
                  link={key}
                  pieChart
                  scales={null}
                  options={DEFAULT_PLUGINS}
                />
              );
            });
          }
          const datasets =
            chartTitle === SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS ||
            chartTitle === SIDE_BAR.points.PERCENT_OF_ASSETS_REF_TABLE
              ? value
                  .filter((item) => !item.xlsx)
                  .sort((item) => (item.type === 'line' ? -1 : 1))
                  .map((item, index) => {
                    if (key === PERCENT_OF_TOTAL_GROSS_CREDIT || key === PERCENT_OF_TOTAL_RISK) {
                      const matchingKey = `${item.table}-${item.column}-${item.row}`;
                      const matchingLabel =
                        selectedDataLabels.find((label) => label.key === matchingKey)?.title ||
                        item.label;

                      return {
                        label: matchingLabel,
                        data: item.data,
                        fill: false,
                        backgroundColor: colors[index],
                        borderColor: colors[index],
                        type: item.type,
                        yAxisID: item.type === 'line' ? 'y1' : 'y',
                      };
                    }
                    return {
                      label: isBA200Selected
                        ? `${selectedDataLabels[index]?.title} ${selectedDataLabels[index]?.key}`
                        : item.label,
                      data: item.data,
                      fill: false,
                      backgroundColor: colors[index],
                      borderColor: colors[index],
                      type: item.type,
                      yAxisID: item.type === 'line' ? 'y1' : 'y',
                    };
                  })
              : value
                  .filter((item) => !item.xlsx)
                  .map((item, index) => {
                    if (key === PERCENT_OF_TOTAL_GROSS_CREDIT || key === PERCENT_OF_TOTAL_RISK) {
                      const matchingKey = `${item.table}-${item.column}-${item.row}`;
                      const matchingLabel =
                        selectedDataLabels.find((label) => label.key === matchingKey)?.title ||
                        item.label;

                      return {
                        label: matchingLabel,
                        data: item.data,
                        fill: false,
                        backgroundColor: colors[index],
                        borderColor: colors[index],
                        type: item.type,
                      };
                    }
                    return {
                      label: isBA200Selected
                        ? `${selectedDataLabels[index]?.title} ${selectedDataLabels[index]?.key}`
                        : item.label,
                      data: item.data,
                      fill: false,
                      backgroundColor: colors[index],
                      borderColor: colors[index],
                      type: item.type,
                    };
                  });

          const filteredDates =
            key === 'MARKET_TOTAL_WITH_CRISIS_INDEX' && inflationByYearsSelected
              ? Object.values(
                  entries[0][1].reduce((acc, date) => {
                    const [year, month] = date.split('-');
                    if (!acc[year] || acc[year].split('-')[1] < month) {
                      acc[year] = date; // Keep the latest month
                    }
                    return acc;
                  }, {}),
                )
              : entries[0][1];

          const data = {
            labels: filteredDates.map((label) => transformQuarterString(label)),
            datasets,
          };
          const scales =
            chartTitle === SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS ||
            chartTitle === SIDE_BAR.points.PERCENT_OF_ASSETS_REF_TABLE
              ? {
                  y: {
                    type: 'linear',
                    display:
                      chartTitle === SIDE_BAR.points.MARKET_TOTAL_AND_AS_PERCENT_OF_TOTAL_ASSETS,
                    position: 'left',
                  },
                  y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                      callback: (tickValue) => `${tickValue}%`,
                    },
                  },
                }
              : null;

          const getOptions = (chartLabels) => {
            if (chartLabels.length > 21) {
              return {
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              };
            }
            if (chartLabels.length < 21 && chartLabels.length > 6) {
              return {
                plugins: {
                  legend: {
                    labels: {
                      font: {
                        size: 8,
                      },
                    },
                  },
                },
              };
            }

            return DEFAULT_PLUGINS;
          };
          const download = { ...value[value.length - 1] };
          return (
            <Charts
              title={chartTitle}
              data={data}
              key={key}
              link={key}
              download={download.xlsx}
              scales={scales}
              options={getOptions(value)}
            />
          );
        }
        return null;
      });
      return charts.filter((chart) => chart !== null);
    }
    return null;
  };

  return (
    <>
      {targetKeys && chartsData && <SeriesSelectedTable data={getTableData()} />}
      <Styled.DashboardPageWrapper ref={chartsRef}>
        {loading ? <Loader /> : null}
        {chartsData ? renderCharts() : null}
      </Styled.DashboardPageWrapper>
      {chartsData && <PrintDialog componentToPrint={renderCharts} />}
    </>
  );
};

export default DashboardPage;

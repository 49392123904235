/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SaveOutlined, PrinterOutlined } from '@ant-design/icons';
import { Line, Bar, Pie } from 'react-chartjs-2';
import * as Styled from './Charts.styles';
import { openPrintDialog } from '../SideBar/actions';
import { selectors } from '../SideBar/reducer';
import CHART_SETTINGS from './constants';
import { BAR_CHART_ENABLED } from '../SideBar/constants';

const Charts = ({ title, data, options, getFile, link, download, scales, pieChart, number }) => {
  const chartRef = useRef();
  const dispatch = useDispatch();

  const printDialogOpen = useSelector(selectors.printDialogOpen);

  return (
    <Styled.ChartsBlockWrapper>
      <Styled.ChartContentWrapper ref={chartRef}>
        <Styled.ChartHeader>
          {title && <Styled.ChartTitle>{title}</Styled.ChartTitle>}
          <div className="controls__wrapper">
            {!printDialogOpen && (
              <>
                <a href={download}>
                  <Styled.ChartControl onClick={() => getFile && getFile()}>
                    <SaveOutlined />
                  </Styled.ChartControl>
                </a>
                <Styled.ChartControl onClick={() => dispatch(openPrintDialog(true))}>
                  <PrinterOutlined />
                </Styled.ChartControl>
              </>
            )}
          </div>
        </Styled.ChartHeader>

        <Link to={`/charts/${link}/${number}`}>
          {pieChart ? (
            <Pie data={data} options={{ ...CHART_SETTINGS.options, ...options, scales }} />
          ) : BAR_CHART_ENABLED.includes(title) ? (
            <Bar data={data} options={{ ...CHART_SETTINGS.options, ...options, scales }} />
          ) : (
            <Line data={data} options={{ ...CHART_SETTINGS.options, ...options, scales }} />
          )}
        </Link>
      </Styled.ChartContentWrapper>
    </Styled.ChartsBlockWrapper>
  );
};

Charts.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  download: PropTypes.string,
  data: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  scales: PropTypes.shape({}),
  pieChart: PropTypes.bool,
  getFile: PropTypes.func,
  number: PropTypes.number,
};

Charts.defaultProps = {
  title: null,
  link: null,
  download: null,
  scales: null,
  pieChart: null,
  number: null,
  getFile: null,
};

export default Charts;

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../actions';
import { selectors } from '../reducer';
import * as Styled from '../SideBar.styles';
import { openNotification, NOTIFICATIONS_CONFIG } from '../../../global/notifications';

const UploadModal = () => {
  const dispatch = useDispatch();

  const [password, setPassword] = useState('');
  const [file, setFile] = useState(null);

  const isModalVisible = useSelector(selectors.uploadFileOpen);

  const handleCloseModal = () => {
    dispatch(actions.openUploadFilesModal(false));
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (!file) {
      openNotification({
        type: NOTIFICATIONS_CONFIG.types.warning,
        message: 'Please select file',
      });
      return;
    }

    dispatch(
      actions.uploadFile.request({
        file,
        password,
      }),
    );
  };

  return (
    <Styled.UploadModal
      title="Upload File"
      visible={isModalVisible}
      onCancel={handleCloseModal}
      footer={null}
    >
      <Styled.SideBarInputHeading>Enter Password</Styled.SideBarInputHeading>
      <Styled.SideBarInput
        type="password"
        placeholder="Enter your password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Styled.ActionButton onClick={handleUpload}>Upload</Styled.ActionButton>
      <Styled.FileInputContainer>
        <Styled.HiddenFileInput
          type="file"
          id="file-upload"
          accept=".xlsx"
          onChange={handleFileChange}
        />
        <Styled.StyledFileButton htmlFor="file-upload">Choose File</Styled.StyledFileButton>
        {file && <Styled.FileNameDisplay>{file.name}</Styled.FileNameDisplay>}
      </Styled.FileInputContainer>
    </Styled.UploadModal>
  );
};

export default UploadModal;

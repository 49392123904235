import { call, put, all, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { saveAs } from 'file-saver';
import SideBarService from './service';
import * as actions from './actions';
import { parseTargetKeys } from '../../global/helpers';
import { NOTIFICATIONS_CONFIG, openNotification } from '../../global/notifications';

export function* getDatabaseSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getDatabases, payload);
    yield put(actions.getDatabase.success(response.data));
  } catch (err) {
    yield put(actions.getDatabase.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Databases Data Load Failed',
      description: err.message,
    });
  }
}

export function* getBanksSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getBanks, payload);
    yield put(actions.getBanks.success(response.data));
    if (response.data.length === 1) {
      yield put(actions.setSelectedBanks(response.data[0].name));
    }
  } catch (err) {
    yield put(actions.getBanks.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Banks Data Load Failed',
      description: err.message,
    });
  }
}

export function* getDataSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getSelectData, payload);
    yield put(actions.getData.success(response.data));
  } catch (error) {
    yield put(actions.getData.error(error));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Data for Select Load Failed',
      description: error.message,
    });
  }
}

export function* getOperationsSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getOperations, payload);
    yield put(actions.getOperations.success(response.data.operations));
  } catch (error) {
    yield put(actions.getOperations.error(error));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Operations Load Failed',
      description: error.message,
    });
  }
}

export function* getChartsSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getCharts, payload, payload.db);
    yield put(actions.getCharts.success(response.data.result[0]));
  } catch (err) {
    yield put(actions.getCharts.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Charts data loading error',
      description: err.message,
    });
  }
}

export function* getColumnsSaga() {
  try {
    const response = yield call(SideBarService.getColumns);
    yield put(actions.getColumns.success(response.data[0].columns));
  } catch (err) {
    yield put(actions.getColumns.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Columns Loading Failed',
      description: err.message,
    });
  }
}

export function* getSelectDataTemplateSaga() {
  try {
    const response = yield call(SideBarService.getSelectedDataTemplate);
    yield put(actions.getTemplate.success(response.data));
  } catch (err) {
    yield put(actions.getTemplate.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Template Loading Failed',
      description: err.message,
    });
  }
}

export function* postSelectDataTemplateSaga({ payload }) {
  try {
    const response = yield call(SideBarService.saveTemplate, payload);
    yield put(actions.addTemplate(response.data));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.success,
      message: 'Template Was Created',
    });
  } catch (err) {
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Template Saving Failed',
      description: err.message,
    });
  }
}

export function* postDatabaseSurvey({ payload }) {
  try {
    const { file, password } = payload;

    if (!file || !(file instanceof File)) {
      yield call(openNotification, {
        type: NOTIFICATIONS_CONFIG.types.error,
        message: 'Invalid file',
      });
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('password', password);
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.info,
      message: 'Survey Start Uploading',
    });
    const response = yield call(SideBarService.uploadSurveyData, formData);
    if (response.status === 200) {
      yield call(openNotification, {
        type: NOTIFICATIONS_CONFIG.types.success,
        message: 'Survey Data Was Uploaded',
      });
    }
  } catch (err) {
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Survey Data Upload Failed',
      description: err.message,
    });
  }
}

export function* getExportPrivateBanks({ payload }) {
  try {
    const response = yield call(SideBarService.getPrivateBanksExportData, payload);

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    saveAs(blob, 'PrivateBanksExport.xlsx');
  } catch (err) {
    yield put(actions.getExportPrivateBanks.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Export Private Banks Failed',
      description: err.message,
    });
  }
}

export function* getSelectedTemplateSaga({ payload }) {
  try {
    const response = yield call(SideBarService.getTemplateById, payload.id);
    yield put(actions.getSelectedTemplate.success(response.data));
    if (response.data.id) {
      const banks = yield call(SideBarService.getBanks, payload.db);
      yield put(actions.getBanks.success(banks.data));
      const selectedBanks = banks.data
        .filter((b) => response.data.extra_data.banks_id_list.includes(b.id))
        .map((b) => b.name);
      const parsedTables = parseTargetKeys(
        response.data.extra_data.tables,
        response.data.extra_data.db,
      );
      const databases = response.data.extra_data.db.split(',');
      if (databases.includes('consumer_credit')) {
        yield put(actions.updateDataBase(['Consumer credit']));
      } else {
        yield put(actions.updateDataBase(databases));
      }
      yield put(actions.getData.request(response.data.extra_data.db));
      yield put(actions.getOperations.request(response.data.extra_data.db));
      const formattedPeriod = response.data.extra_data.period.map((date) => moment(date));
      yield put(actions.updatePeriod(formattedPeriod));
      const validKeys = Object.keys(response.data.extra_data.trigger).filter(
        (key) => response.data.extra_data.trigger[key] === true,
      );
      if (validKeys.includes('MARKET_TOTAL_WITH_CRISIS_INDEX')) {
        yield put(actions.updateInflationChecked(true));
      }
      if (validKeys.includes('MARKET_TOTAL_WITH_CRISIS_INDEX_BY_YEAR')) {
        yield put(actions.updateInflationByYear(true));
      }
      yield put(actions.setBanks(selectedBanks));
      yield put(actions.setDataPoints(validKeys));
      yield put(actions.updateKeys(parsedTables));
    }
  } catch (err) {
    yield put(actions.getSelectedTemplate.error(err));
    yield call(openNotification, {
      type: NOTIFICATIONS_CONFIG.types.error,
      message: 'Template Loading Failed',
      description: err.message,
    });
  }
}

export function* sideBarSagaWatcher() {
  yield all([
    takeLatest(actions.getDatabase.REQUEST, getDatabaseSaga),
    takeLatest(actions.getData.REQUEST, getDataSaga),
    takeLatest(actions.getOperations.REQUEST, getOperationsSaga),
    takeLatest(actions.getBanks.REQUEST, getBanksSaga),
    takeLatest(actions.getColumns.REQUEST, getColumnsSaga),
    takeLatest(actions.getCharts.REQUEST, getChartsSaga),
    takeLatest(actions.getTemplate.REQUEST, getSelectDataTemplateSaga),
    takeLatest(actions.saveTemplate.REQUEST, postSelectDataTemplateSaga),
    takeLatest(actions.getSelectedTemplate.REQUEST, getSelectedTemplateSaga),
    takeLatest(actions.uploadFile.REQUEST, postDatabaseSurvey),
    takeLatest(actions.getExportPrivateBanks.REQUEST, getExportPrivateBanks),
  ]);
}

export default sideBarSagaWatcher;
